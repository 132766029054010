import React from 'react';

export default (props) => {
  const { currentPage, numPages, pagesSlug } = props;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : `/${pagesSlug}/${currentPage - 1}`;
  const nextPage = `/${pagesSlug}/${currentPage + 1}`;

  if (numPages <= 1) {
    return <section className="pagination"></section>;
  }

  const pages = Array.from({ length: numPages }).map((el, index0) => {
    const index = index0 + 1;
    if (index === currentPage) {
      return (
        <li key={index0} className="active">
          {index}
        </li>
      );
    }

    return (
      <li key={index0}>
        <a href={`/${index0 === 0 ? '' : pagesSlug + '/' + index}`}>{index}</a>
      </li>
    );
  });

  return (
    <section className="pagination">
      <div className="inner">
        <nav>
          <ul>
            {!isFirst && (
              <li>
                <a href={prevPage}>Previous</a>
              </li>
            )}
            {pages}
            {!isLast && (
              <li>
                <a href={nextPage}>Next</a>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </section>
  );
};
