import React from 'react';
import PagePreview from './page-preview';

export default (props) => {
  if (props.pages.length < 1) {
    return (
      <section className="page-listing">
        <div className="inner">No Pages</div>
      </section>
    );
  }

  return (
    <section className="page-listing bg-light-grey">
      <div className="inner">
        <div className={`row${props.pages.length < 3 ? ' justify-center' : ''}`}>
          {props.pages.map((page, index) => {
            return (
              <div className="col col-33" key={index}>
                <PagePreview {...page.node} index={index} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
