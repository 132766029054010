import React from 'react';

export default (props) => (
  <section className="index-intro">
    <div className="inner inner--small">
      <p className="lead">
        Working from home may be the new normal, but not all activities translate well from
        in-person to digital. We need to look at new ways to strategise, design and make together –
        when we can’t be together.
      </p>
      <p>
        Isobar has a lot of experience transforming businesses digitally. Now we’re opening up that
        knowledge to serve all of our clients and network better. Which is why we have created this
        series of webinars, designed to rapidly adjust ways of working to navigate the impact of
        COVID-19.
      </p>
      <p>
        Below you can find the links to the webinars, and please feel free to ask us any questions
        you might have!{' '}
      </p>
    </div>
  </section>
);
