import React from 'react';
import RichTextPreview from './richtext-preview';
import { ArrowRight } from './icons';

export default (props) => {
  const previewText = <RichTextPreview {...props.introduction} />;

  function getYoutubeThumbnail(url) {
    const id = url.substring(url.lastIndexOf('/') + 1);
    return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
  }

  return (
    <a href={`/${props.slug}/`} className="page-preview">
      <div className="content-wrap">
        <p className="title">{props.title}</p>
        <div className="preview">{previewText}</div>
        <p className="read-more">
          <ArrowRight /> Read more
        </p>
      </div>
    </a>
  );
};
