import React from 'react';
import RichText from './richtext';

export default (props) => {
  let introduction;

  if (props.introduction) {
    if (typeof props.introduction === 'object' && 'json' in props.introduction) {
      introduction = <RichText {...props.introduction} />;
    } else {
      introduction = props.introduction;
    }
  }

  return (
    <section className="index-hero">
      <div className="inner">
        <div className="content">
          <h1>{props.title}</h1>
          <div className="subcopy">
            The rapid global spread of COVID-19 is changing how we operate and work together. These
            changes are at once daunting and exciting – from disruption; innovation is born. But how
            do we adapt to this new way of working and maximise opportunity along the way?
          </div>
        </div>
      </div>
    </section>
  );
};
