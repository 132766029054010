import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import IndexHero from '../components/index-hero';
import IndexIntro from '../components/indexintro';
import PageListing from '../components/page-listing';
import Pagination from '../components/pagination';

class RootIndex extends React.Component {
  render() {
    const pages = this.props.data.allContentfulPages.edges;

    return (
      <Layout location={this.props.location}>
        <IndexHero title="Isobar Webinars" />
        <IndexIntro />
        <PageListing pages={pages} />
        <Pagination {...this.props.pageContext} />
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allContentfulPages(sort: { fields: [createdAt], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          slug
          id
          title
          introduction {
            json
          }
          createdAt
          youtubeUrl
        }
      }
    }
  }
`;
